//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {hs} from '@/apis/tool.js'

export default {
	data: ()=> ({
		query: '',
		dataSource: [],
		inRNBox: false,
		navigationBarBackgroundColor: '#437AFF',
	}),
	created() {
		this.inRNBox = window.ReactNativeWebView || !!this.$route.query.inRNBox || false
		if(this.inRNBox) {
			this.navigationBarBackgroundColor = '#' + (this.$route.query.navigationBarBackgroundColor || '154B79')
		}
	},
	mounted() {
		this._getList('4403999019')
	},
	methods: {
		async _getList(params) {
			let {data = []} = await hs(params)
			this.dataSource = data.data|| []
		},
		async onSearch() {
			this.query = this.query.toLocaleUpperCase()
			this.query = this.query ? this.query.trim() : ''
			await this._getList(this.query)
		},
		showDetail(title, list) {
			this.$dialog.alert({
				title,
      			message: list.replace(/\$/g, '\n'),
   			});
		}
	}
}
