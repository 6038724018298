import axios from 'axios';
import qs from 'qs';

const headers = {}
headers['Content-Type']= 'application/x-www-form-urlencoded';

export function queryAirline({code, cn}) {
	return new Promise((resolve, reject)=> {
		let success = (res)=> {
			resolve(res)
		}, fail = err=> {
			!!err && console.error(err)
			resolve([])
		},
		params={
			code, cn,
		}
		axios.get('/api-tool/airline/query',{params} ).then(success, fail)
	})
}

export function queryTact({dep, des}) {
	return new Promise((resolve, reject)=> {
		let params = {
			dep, des
		}, success = res => {
			resolve(res.data)
		}, fail = err => {
			resolve([])
		}
		axios.get('/api-tool/tact/query', {params}).then(success, fail)
	})
}

export function planeModel({genType}) {
	return new Promise((resolve, reject)=> {
		let success = res => {
			resolve(res)
		}, fail = err => {
			resolve([])
		},
		params = {
			genType,
		}
		axios.get('/api-tool/plane-model/query', {params}).then(success, fail)
	})	
}

export function flightPlan(params) {
	return new Promise((resolve, reject)=> {
		let success = res => {
			resolve(res)
		}, fail = err => {
			resolve([])
		}
		axios.get('/api-tool/flight-plan/query', {params} ).then(success, fail)
	})
}

// 2021-3-16 from Li Mingrui@saas
export function iata({cn, en}) {
	return new Promise((resolve, reject)=> {
		let params = {
			cn, en
		}, success = res => {
			resolve(res)
		}, fail = err => {
			resolve([])
		}
		axios.get('/api-tool/iata/query', {params}).then(success, fail)
	})
}

// 2021-3-16 from Li Mingrui@saas
export function hs(key) {
	//http://tom.t.efreight.cn/afbase/api/hsList/3208201099
	return new Promise((resolve, reject)=> {
		let success = res => {
			resolve(res.data)
		}, fail = err => {
			resolve([])
		},
		params = {
			query: key,
		}
		axios.get('/api-tool/hs/query', {params}).then(success, fail)
	})
}
